@import url('https://fonts.googleapis.com/css?family=Montserrat');


.header-1 {
  max-width: 60em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  margin: 10px auto;
  text-align: center;
}
.header-1-item {
  width: 28em;
  position: relative;
  margin: 10px;
  margin-bottom: 30px;
}

.header-1-item h2 {
  font-weight: 500;
  color: #151515;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0;
  font-size: 48px;
  line-height: 1.45833;
  /* margin-left: 10px; */
}
.header-1-item p:first-of-type {
  margin-top: 20px;
  font-size: 18px;
  /* line-height: 1.55556; */
  color: #4d4d4d;
}


.header-1-item p:last-of-type {
  margin-top: 17px;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-size: 15.5px;
  line-height: 1.5;
  font-weight: 400;
  color: var(--desc-color);
}

.header-1-heading {
  display: flex;
}

.divider::before {
  content: "";
  display: inline-block;
  position: relative;
  bottom: 10px;
  right: 10px;
  width: 70px;
  height: 3px;
  background-color: var(--theme-color);
  margin: auto;
  box-sizing: border-box;
}


@media only screen and (max-width: 1000px) {
  .header-1-item h2{
    font-size: 35px;
  }
}