.main {
  max-width: 550px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  padding: 0 10px;
}

.main h2 {
  font-size: 35px;
  font-family: sans-serif;
  font-weight: normal;
}

.main a {
  font-size: 15px;
  /* color: red; */
}
.main img {
  height: 70px;
  margin-bottom: 20px;
}

.links {
  display: flex;
  justify-content: space-between;
}




@media only screen and (max-width: 450px) {
  .links {
      flex-direction: column;
  }
}
