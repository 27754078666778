.main {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
  position: relative;
}

.item {
  flex-grow: 1;
  width: 100%;
  /* height: 500px; */
}

.item:last-of-type {
  /* max-width: 500px; */
  background-color: #fff6f4;
  box-sizing: border-box;
  padding: 50px;
  padding-left: 60px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.item2 {
  max-width: 600px;
}

.divider {
  display: inline-block;
  bottom: 10px;
  right: 10px;
  width: 100px;
  height: 3px;
  background-color: var(--theme-color);
  /* margin: auto; */
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 10px;
}

.headline {
  font-size: 43px;
  font-weight: 400;
  color: #151515;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.5px;
}

.desc {
  /* max-width: 80%; */
  /* margin: auto; */
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 400;
  color: var(--desc-color);
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  /* text-align: center; */
}

.textfield {
  padding: 0px;
  margin: 5px 5px;

  font-family: "Montserrat", sans-serif;
}

.button {
  margin-top: 10px;
  border-radius: 2px;
  padding: 10px 40px;
  /* margin: 60px auto; */
  /* margin-bottom: 20px; */
  font-size: 15px;
  letter-spacing: 0.4px;
  font-family: "Montserrat", sans-serif;

  text-transform: uppercase;
}

@media only screen and (max-width: 1000px) {
  .main {
    flex-direction: column;
  }
  .item:first-of-type {
    height: 350px;
  }

  .item:last-of-type {
    align-items: center;
    text-align: center;
  }
  .headline {
    font-size: 35px;
  }
}
