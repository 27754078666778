@import url('https://fonts.googleapis.com/css?family=Montserrat');


.main {
  width: 100%;
  /* max-width: 60em; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  margin: 10px auto;
  text-align: center;
}

.main-item {
  width: 50%;
  /* min-width: 400px; */
  flex-grow: 1;
}
.main-item-1 {
  display: flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}
.main-item-2 {
  background-size: cover;
  height: 600px;
  background-position: center;
}
.service-item {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 300px;
  background-color: aliceblue;
  border: #00000007 solid 1px;
}
.service-item h3 {
  margin-bottom: 15px;
  margin-top: 25px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 24px;
  /* line-height: 2px; */
  letter-spacing: 1px;
}
/* .service-item p {
} */
.service-item .svg {
  margin: auto;
  height: 80px;
  width: 80px;
  background-color: var(--theme-color);
  border-radius: 100%;
}
.service-item p {
  font-size: 16px;
  letter-spacing: 0.5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: var(--desc-color);

}

@media only screen and (max-width: 1300px) and (min-width: 1001px) {
  .main-item-2 {
    max-width: 30%;
  }
}

@media only screen and (max-width: 1000px) {
  .main-item-1 {
    width: 100%;
  }
  .main-item-2 {
    width: 100%;
    height: 400px;
  }
}
@media only screen and (max-width: 750px) {
  .service-item {
    width: 750px;
    /* flex-grow: 1; */
  }
}
