.top-header {
  width: 100%;
  height: 45px;
  background-color: var(--theme-color);
}

.main-header {
  display: flex;
  justify-content: space-between;
  flex-flow: row;
  align-items: center;
  background-color: var(--theme-color3);
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 25px;
}

.header_text {
  font-family: "Montserrat";
  color: #151515;
  font-size: 15.5px;
  line-height: 1.2;
  transition: 0.25s;
  letter-spacing: 0.05em;
  font-weight: 500;
}

.header-item-1 {
  width: 120px;
  margin-left: 10px;
}
.header-item-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.mainlabel {
  width: fit-content;
  text-align: center;
  border-radius: 5px;
  background-color: white;
  /* padding: 15px 40px; */
  position: absolute;
  left: 50%;
  bottom: 8%;
  transform: translate(-50%, -50%);
  padding: 14px 4%;
}



@media only screen and (max-width: 650px) {
  .main-header {
    flex-direction: column;
  }
  .header_text {
    font-size: 14px;
    letter-spacing: 0.8px;
  }
  .mainlabel {
    bottom: 0%;
  }
}
@media only screen and (max-width: 450px) {
  .mainlabel {
    padding: 10px 20px;
  }
  .header-item-1 {
    margin-left: 0;
  }
}
