.main {
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  padding: 0 10px;
}

.inner {
  width: 100%;
  max-width: 800px;
  /* margin: 0%; */
}
.main .break {
  width: 20px;
}

.name {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.name .break {
  width: 20px;
}

.btn {
  height: 45px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
}
.heading {
  /* background: var(--theme-color); */
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  background-color: var(--theme-color3);
  /* border-bottom: 2px solid rgb(126, 195, 255); */
  width: 100%;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.5px;
  font-weight: bold;
  padding: 8px;
  /* border-radius: 5px; */
}

.bookingsub {
  border: 2px solid rgba(0, 0, 0, 0.171);
  border-radius: 5px;
  width: 100%;
  margin: 10px 0px;
  padding: 10px 10px;
}

.bookingsub span{
  font-weight: bold;

}
.bookingsub p{
  font-size: 13.5px;
}

@media only screen and (max-width: 450px) {
  .heading {
    font-size: 15px;
  }
  .name {
    flex-direction: column;
  }
}
@media only screen and (max-width: 950px) {
  .heading {
    font-size: 17px;
  }
  .main {
    flex-direction: column;
  }
}
