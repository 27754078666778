.container {
  max-width: 1200px;
  margin: auto;
  padding-top: 55px;
}

.divider {
  content: "";
  display: inline-block;
  bottom: 10px;
  right: 10px;
  width: 100px;
  height: 3px;
  background-color: var(--theme-color);
  margin: auto;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 10px;
}
.divider2 {
  width: 50px;
}

.headline {
  font-size: 43px;
  font-weight: 400;
  color: #151515;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.5px;
}

.desc {
  max-width: 80%;
  margin: auto;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 400;
  color: var(--desc-color);
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  text-align: center;
}

.desc {
  margin-top: 20px;
  margin-bottom: 30px;
}

.services {
  column-count: 2;
  column-gap: 30px;
  width: 100%;
}
.service {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 25px 20px 25px 0;
  border-bottom: 1px solid #e7e7e7;
}

.service h4,
p,
span {
  font-family: "Montserrat", sans-serif;
}
.service p {
  color: var(--desc-color);
}

.button{
  border-radius: 100px;
  padding: 10px 40px;
  margin: 60px auto;
  margin-bottom: 80px;
  font-size: 15px;
  letter-spacing: .4px;
  font-family: "Montserrat", sans-serif;

  text-transform: uppercase;
}

@media only screen and (max-width: 1000px) {
  .headline {
    font-size: 30px;
  }
  .services {
    column-count: 1;
    width: 90%;
  }
  .service h4,
  p,
  span {
    font-size: 15px;
  }
}
