:root {
  --theme-color: #ffc6e2;
  --theme-color2: #d92a7c;
  --theme-color3: #f7f2ef;
  --theme-color4: #FFBDB1;

  --desc-color: #8f8f8f;




  /* --desc-color2: #757575; */
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.MuiFormControlLabel-label {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
