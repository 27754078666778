.main {
  width: 100%;
  height: 180px;
  background-size: cover;
  background-position: center;
  background-color: var(--theme-color3);

}

.bg {
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  height: 100%;
}
.main h1 {
  padding-left: 15px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0;
  font-size: 52px;
  line-height: 1.2;
  font-weight: 700;
}

.breadcrumbs {
  padding: 15px 0;
  background-color: var(--theme-color3);
}
.breadcrumbs2 {
  max-width: 1200px;
  margin: auto;
  font-family: "Montserrat", sans-serif;
  padding-left: 15px;
}

.breadcrumbs2 p,
a {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

@media only screen and (max-width: 1000px) {
  .main {
    height: 130px;
  }
  .main h1 {
    font-size: 31px;
    padding-left: 6%;
  }
  .breadcrumbs2 {
    padding-left: 6%;
  }
}
@media only screen and (max-width: 600px) {
  .main {
    height: 110px;
  }
  .main h1 {
    padding-left: 6%;
    font-size: 25px;
    font-weight: normal;
  }
  .breadcrumbs2 {
    padding-left: 6%;
  }
}
