.main {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
  margin-top: 10px;
  

}


.main2{
  border: 1.5px solid var(--theme-color);


}

.sections {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;

}

.section {
  width: 100%;
  flex-grow: 100;
  text-align: center;
  display: flex;
  flex-direction: column;

}
.categories {
  width: 600px;
  /* flex-shrink: 0; */
  flex-grow: 0;

}
.services {
  border-left: 1.5px solid var(--theme-color);
  border-right: 1.5px solid var(--theme-color);
}

.inner_section {
  min-height: 350px;
  max-height: 500px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0 1vw;
  
}

.section h3 {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  padding: 10px;
}

.Divider {
  height: 1px;
  width: 100%;
  background-color: var(--theme-color);
}

.label {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.label p {
  font-size: 14.5px;
  text-align: start;
}

.specs {
  width: 90px;
  /* min-width: 60px; */
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
}

.horiCategories {
  display: none;
}

.miniSections {
  display: none;
}

.Button {
  display: flex;
  justify-content: flex-end;
}
.Button button {
  width: 230px;
  height: 45px;
  margin: 10px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  padding: 10px;
}

@media only screen and (max-width: 1200px) {
  .services {
    border-left: none;
  }
  .categories {
    display: none;
  }
  .horiCategories {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }
  .cateTextTop {
    font-size: 17px;
    border-bottom: 1.5px solid var(--theme-color);

    /* font-weight: bold; */
  }

  .horiCategories2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }
}


@media only screen and (max-width: 750px) {
  .sections {
    display: none;
  }

  .miniSections {
    display: flex;
    flex-direction: column;
    padding: 0 1vw;
  }

  .miniSections button {
    font-family: "Montserrat", sans-serif;
  }
}
